<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">邮箱换绑</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="regmid bgf ">
        <div class="midhead borbot font14 col9">
            <p class="col24">01.身份验证</p>
            <div class="pad14">
                <img v-if="step>=2"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=2?'col24':''">02.重新绑定</p>
            <div class="pad14">
                <img v-if="step>=3"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=3?'col24':''">03.完成</p>
        </div>

        <div v-if="step==1" class="step2">
            <ul>
                <li>
                    <label for="">已绑定邮箱号</label>
                    <p>{{ info.email }}</p>
                </li>
                <li>
                    <label for="">邮箱验证码：</label>
                    <el-input v-model="info.authCode" class="w227" placeholder="输入验证码" size="large"/>
                    <button @click="handleGetSms_step1(info.email)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取邮箱验证码'}}</button>
                </li>
                <li>
                    <label for=""></label>
                    <el-button @click="verifyTheOriginalEmail" type="primary" class="w125" size="large" >
                        下一步
                    </el-button>
                </li>
            </ul>

        </div>
        <div v-else-if="step==2" class="step2">
            <ul>
                <li>
                    <label for="">新邮箱号：</label>
                    <el-input class="w300" v-model="info.newEmail" placeholder="输入新邮箱号" size="large"/>
                </li>
                <li>
                    <label for="">邮箱验证码：</label>
                    <el-input class="w227" v-model="info.newAuthCode" placeholder="输入验证码" size="large"/>
                    <button @click="handleGetSms_step2(info.newEmail)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取邮箱验证码'}}</button>
                </li>
                <li>
                    <label for=""></label>
                    <el-button @click="bindANewEmail" type="primary" class="w125" size="large" >
                        下一步
                    </el-button>
                </li>
            </ul>

        </div>
        <div v-if="step==3" class="step4">
            <div class="flex flexa flexc padb40 padt20">
                <img :src="utils.loadImg('success.png')" alt="" width="43" height="43" srcset="">
               <p class="col24 font22 padl20 ">恭喜您，换绑成功！</p>
            </div>
            <p class="h20"></p>
            
            <el-button @click="$router.push('/account')" type="primary" class="w172" size="large" >
                返回设置中心
            </el-button>
        </div>
    </div>
</template>
<script>
import { sendResetPasswordEmail, editInfo } from "@/utils/api1.js"
import { Back } from '@element-plus/icons-vue'
export default {
    components: {
        Back
    },
    data(){
        return {
            info: {
                email: this.$route.query.email,
                authCode: '',
                newEmail: '',
                newAuthCode: '',
            },
            yzmSend: false,
            timmer: null,
            time: 60,
            step: 1,
        }
    },
    mounted(){
        
    },
    methods: {
        handleGetSms_step1(email) {
            let that = this;
            if (that.yzmSend) return;

            sendResetPasswordEmail({ mobile: email }).then(res=>{
                console.log(res);
                if(res){
                    that.$message.success(res.message);
                }
            });
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },
        async verifyTheOriginalEmail() {
            try {
                if (!this.info.authCode) {
                    return this.$message.warning('请输入邮箱验证码')
                }
                const result = await this.$api.user.verifyTheOriginalEmail({
                    mobile: this.info.email,
                    authCode: this.info.authCode,
                })
                console.log(result, 'resul')
                if (result.status == 200) {
                    this.$message.success('验证成功')
                    this.step ++
                    this.yzmSend = false
                    clearInterval(this.timmer)
                    this.time = 60
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.info.emailLoading = false
            }
        },
        handleGetSms_step2(email) {
            let that = this;
            if (that.yzmSend) return;
            if (!email) {
                return this.$message.warning('请输入邮箱号！')
            } else if (!new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$").test(email)) {
                return this.$message.warning('邮箱号格式有误！')
            }

            sendResetPasswordEmail({ mobile: email }).then(res=>{
                console.log(res);
                if(res){
                    that.$message.success(res.message);
                }
            });
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },
        async bindANewEmail() {
            try {
                if (!this.info.newAuthCode) {
                    return this.$message.warning('请输入邮箱验证码')
                }
                const result = await this.$api.user.bindANewEmail({
                    mobile: this.info.newEmail,
                    authCode: this.info.newAuthCode,
                })
                console.log(result, 'resul')
                if (result.status == 200) {
                    this.step ++
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.info.emailLoading = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.w227{ width: 227px;}
.w172{ width: 172px;}
.w125{ width: 125px;}
.padt3{ padding-top: 3px;}
.w400{ width: 400px;}
.mw{
    min-width: 1090px;
}
.img1{
    width: 96px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #E6E8EB;
    margin-left: 10px;
}
.step4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
}
.step2{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    >ul{
        color: #3F4651;
        font-size: 12px;
        >li{
            padding-bottom: 36px;
            display: flex;
            align-items: center;
            >label{
                width: 96px;
                min-width: 96px;

            }
        }
    }
}
.step3{
    padding-top: 45px;
    width: 320px;
    margin:0 auto;
}
.regtop{
    background: #34383F;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
}

.regmid{
    box-sizing: border-box;
    padding: 0 64px 57px;
    min-height: calc(100vh - 140px);
}
.wmid{
    width: 320px;
    margin: 0 auto;
}
.midhead{
    line-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.btn1{
    margin-left: 14px;
    border-radius: 2px;
    color: #71767E;
    font-size: 12px;
    width: 155px;
    min-width: 155px;
    height: 40px;
}
.tabs{
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    color: #242424;
    li>p{
        width: 80px;
        height: 4px;
        background: #fff;
        margin: 16px auto;
    }
    li{
        cursor: pointer;
    }
    .act{
        color: #FF6A00;
        >p{
            background: #FF6A00;
        }
    }
}
</style>